<template src="./ConfigureFieldDeleteModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../../common/AppModal/AppModal";
import {
  deleteNewField,
  getDeleteWarning,
} from "@/services/clubDetails/allAudience.service";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    rowId: {
      type: Number,
      default: null,
    },
    fieldName: {
      type: String,
      default: null,
    },
  },
  computed: {
    modalVisible() {
      return this.$store.state.common.modalVisible;
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      warning: "",
      loader: false,
    };
  },
  mounted() {
    // this.getWarningMessage();
  },
  methods: {
    cancelModal() {
      this.$store.commit("MODAL_VISIBLE_COMMON", false);
    },
    deleteClub() {
      this.$emit("updateLoader", true);
      deleteNewField({
        clubId: this.$route.params.id,
        fieldId: +this.rowId,
      })
        .then((response) => {
          this.$emit("updateLoader", false);
          this.$store.commit("MODAL_VISIBLE_COMMON", false);

          let payload1 = {
            type: "success",
            Message: "Field deleted successfully!",
          };
          this.$emit("deleteFieldnotification", payload1);

          var payload = {
            clubId: this.$route.params.id,
            entityType: +this.$route.query.type,
          };
          this.$store.dispatch("getConfigureFields", payload);
        })
        .catch((error) => {
          this.$emit("updateLoader", false);
        });
    },
    modalHandler(data) {
      this.$store.commit("MODAL_VISIBLE_COMMON", false);
    },
    getWarningMessage() {
      this.loader = true;
      var model = {
        clubId: this.$route.params.id,
        fieldId: +this.rowId,
        entityType: +this.$route.query.type,
      };

      getDeleteWarning(model)
        .then((response) => {
          this.loader = false;
          this.warning = response.data.data;
        })
        .catch((error) => {
          this.loader = false;
        });

      return this.warning;
    },
  },
};
</script>
