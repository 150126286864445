<AppLoader v-if="isLoader"></AppLoader>
<modal :isLoading="isLoading" :title="'Remove Audience'" :handler="modalVisible" :cssClass="'c-modal-small'" @modalHandler="modalHandler">
  <template v-slot:content>
    <div>
      <p class="font-16 m-0 py-2 text-center">
        Are you sure you want to remove selected {{ selectedItems.length > 1 ? 'audiences' : 'audience' }} from this List?
      </p>
    </div>
  </template>
  <template v-slot:footer :class="'justify-content-end modal-footer'">
    <div class="d-flex justify-content-between">
      <div class="me-1 w-50-p">
        <kbutton @click="cancelModal" :class="'w-100'">Cancel</kbutton>
      </div>
      <div class="ms-1 w-50-p">
        <kbutton :theme-color="'primary'" @click="deleteClub" :class="'w-100'">Remove</kbutton>
      </div>
    </div>
  </template>
</modal>