<template>
    <h4 :style="{ fontSize: '1em' }">
        <!-- <span class="k-icon k-i-warning" :style="{ fontSize: '2.5em' }" /> -->
        <span  :style="{ fontSize: '2.5em' }" />
        <br /><br />
        {{messageShow}}
    </h4>
</template>
<script>
export default {
    props: {
        messageShow: {
            type: String,
            default: "No Data",
        },
    },
}
</script>