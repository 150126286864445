<template src="./AddNewFieldModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../../common/AppModal/AppModal";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import AddConfigureFieldModel from "../../../../../../models/audience/allAudience/AddConfigureFieldModel";
import { addNewField } from "@/services/clubDetails/allAudience.service";
import { getFieldById } from "../../../../../../services/clubDetails/allAudience.service";
import AppLoader from "../../../../../common/AppLoader/AppLoader";
export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
    dropdownlist: DropDownList,
    checkbox: Checkbox,
    multiselect: MultiSelect,
    "app-loader": AppLoader,
  },
  data: function () {
    var model = new AddConfigureFieldModel();
    return {
      fieldLabel: "",
      clubId: this.$route.params.id,
      fieldTypeValues: [
        { id: 2, text: "Dropdown" },
        { id: 1, text: "Text" },
        { id: 3, text: "Numeric" },
        { id: 4, text: "Date" },
      ],
      additionalOptions: [],
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
      loader: false,
      model,
      errors: [],
      nameError: "",
      additionalOptionError: "",
      loaderLoading: false,
    };
  },
  computed: {
    modalVisible() {
      return this.$store.state.allAudience.isAddNewFieldModal;
    },
    editModalVisible() {
      return this.$store.state.allAudience.isUpdateFieldModal;
    },
    editFieldprevData() {
      return this.$store.state.allAudience.fieldEdit;
    },
    maxItem() {
      if (this.model.options.length >= 5) {
        return false;
      } else {
        return true;
      }
    },
    isLoaderLoading() {
      return this.$store.state.allAudience.isLoading;
    },
  },
  mounted() {
    if (this.rowId) {
      this.loaderLoading = true;
      this.getField();
    }
  },
  props: {
    rowId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    saveNewField() {
      this.model.id = this.rowId ? +this.rowId.id : 0;
      this.model.entityType = +this.$route.query.type;
      this.model.clubId = this.$route.params.id;

      if (this.nameError || this.additionalOptionError) {
        return;
      }
      this.$store.commit("SET_LOADER", true);
      addNewField(this.model)
        .then((response) => {
          this.$emit("getConfigureFields");
          setTimeout(() => {
            this.$store.commit("SET_LOADER", false);
          }, 1500);

          let messageObj = {
            type: "success",
            Message: this.rowId
              ? "Audience field has been updated."
              : "New field added successfully.",
          };

          this.$emit("addNewFieldnotification", messageObj);
          this.$store.commit("SET_ADD_NEW_FIELD_OPEN", false);
          this.$store.commit("SET_UPDATE_FIELD_OPEN", false);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.$store.commit("SET_LOADER", false);
        });
    },
    onOpen() {
      this.errors.options[0] = "";
    },
    cancelModal() {
      this.$store.commit("SET_ADD_NEW_FIELD_OPEN", false);
      this.$store.commit("SET_UPDATE_FIELD_OPEN", false);
    },
    deleteList() {},
    modalHandler(data) {
      this.$store.commit("SET_ADD_NEW_FIELD_OPEN", false);
      this.$store.commit("SET_UPDATE_FIELD_OPEN", false);
    },
    handlePassStatusValue(e) {
      this.model.fieldType = e.value.id;
      this.model.fieldTypeObj = e.value;
    },
    additionalOptionsOnChange(event) {
      if (event.target.value.length > 5) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: "Max five options can be inputted",
          type: "warning",
        });
      } else {
        let values = event?.target?.value;
        const nonEmptyValues = values.filter((option) => option.trim() !== "");
        if (nonEmptyValues.length < values.length) {
          this.model.options = [...event.target.value];
        }
        const uniqueValues = [
          ...new Set(nonEmptyValues.map((option) => option.trim())),
        ];
        if (uniqueValues.length !== nonEmptyValues.length) {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Duplicate options are not allowed.",
            type: "warning",
          });
        }
        this.model.options = uniqueValues;
      }
    },
    modalName() {
      if (this.rowId) {
        return "Edit Field";
      }
      return "Add New Field";
    },
    getField() {
      getFieldById({
        id: this.rowId?.id,
        clubId: this.clubId,
      })
        .then((response) => {
          this.$store.commit("SET_LOADER", false);
          this.loaderLoading = false;
          this.model.label = response.data.data.label;
          this.model.isVisible = response.data.data.isVisible;
          this.model.isRequired = response.data.data.isRequired;
          this.model.fieldType = response.data.data.fieldType;
          this.model.options = response.data.data.options?.map((x) => x.name);

          if (response.data.data.fieldType == "Text") {
            this.model.fieldTypeObj = { id: 1, text: "Text" };
          }
          if (response.data.data.fieldType == "Dropdown") {
            this.model.fieldTypeObj = { id: 2, text: "Dropdown" };
          }
          if (response.data.data.fieldType == "Numeric") {
            this.model.fieldTypeObj = { id: 3, text: "Numeric" };
          }
          if (response.data.data.fieldType == "Date") {
            this.model.fieldTypeObj = { id: 4, text: "Date" };
          }
        })
        .catch((error) => {});
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = "SSSSSSS"),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
  },
};
</script>
