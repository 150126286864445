export default class AddConfigureFieldModel {
    public clubId: number = 0
    public id: number = 0
    public entityType: number = 0
    public fieldTypeObj = { id: 2, text: "Dropdown" }
    public fieldType: number = 2
    public label: string = ""
    public options = []
    public isVisible: boolean = false
    public isRequired: boolean = false
}
