<modal
  title="Add Audience"
  :handler="visibleModal"
  :isLoading="isLoading"
  :cssClass="'modal-xl-1000 max-height-100-145 content-overflow-hidden'"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <div class="min-h-280 border-top-normal-1 pt-16">
      <Grid
        v-if="memberColumns.length > 0"
        :class="'max-height-100-251 first-col-checkbox-center add-audience-from-table-wrap table-design-2 th-td-checkbox-style-2 mb-2'"
        ref="grid"
        :data-items="membersData?.items"
        :skip="skip"
        :take="take"
        :total="total"
        :pageable="gridPageable"
        :selectable="true"
        :selected-field="selectedField"
        :columns="columns"
        @selectionchange="onSelectionChange"
        @datastatechange="dataStateChange"
        @headerselectionchange="onHeaderSelectionChange"
        :sortable="true"
        :sort="sort"
        @sortchange="sortChangeHandler"
      >
        <toolbar :class="'px-0 pb-12'">
          <div
            class="d-flex flex-wrap justify-content-between w-100 align-items-center"
          >

            <div class="search-box-wrap width-220 w-100-vsm mb-2 mb-sm-0">
              <span class="k-icon k-i-zoom"></span>
              <k-input
                :class="'mb-0 w-100'"
                placeholder="Search"
                @input="debounceSearch"
              ></k-input>
            </div>

            <div>
              <checkbox
                v-if="membersData?.items?.length"
                v-model="forceSelectAll"
                :default-checked="false"
                :label="selectAllLabelGenerator()"
              />
            </div>

          </div>
        </toolbar>

        <template v-slot:contactTemplate="{ props }">
          <td>
            <contact
              v-if="props.rowType !== 'groupHeader'"
              :data-item="props.dataItem"
            ></contact>
          </td>
        </template>
          <template v-slot:nameCell="{ props }">
              <td>
        <span class="font-14 font-w-500 color-blue"
        >{{props.dataItem.name}}</span
        >
              </td>
          </template>

          <template v-slot:subscriberStatusCell="{props}">
              <td>
                  <div class="d-flex justify-content-between">
                      <span v-if="props.dataItem.subscriberStatus==0" class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8">Non-subscribed</span>
                      <span v-if="props.dataItem.subscriberStatus==1" class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 live">Subscribed</span>
                      <span v-if="props.dataItem.subscriberStatus==2" class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 draft">Unsubscribed</span>
                      <span v-if="props.dataItem.subscriberStatus==3" class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 paused">Cleaned</span>
                  </div>
              </td>
          </template>
          <template v-for="(item,index) in dateCells" :key="index" v-slot:[item.cell]="{ props }">
              <td>
                  <span> {{getDateLocal(props.dataItem[item.field])}} </span>
              </td>
          </template>
        <GridNoRecords> No members available. </GridNoRecords>
      </Grid>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <div class="me-1">
        <kbutton @click="modalHandler"  :class="'flex-none border-normal-1 text-color'"
        :theme-color="'primary'"
        :fill-mode="'outline'"
        >Cancel</kbutton>
      </div>
      <div class="ms-1">
        <kbutton
          v-if="forceSelectAll"
          :theme-color="'primary'"
          :class="'flex-none'"
          @click="addMembers"
        >
          Add Audience
        </kbutton>
        <kbutton
          v-else
          :disabled="!selectedItems.length"
          :theme-color="'primary'"
          :class="'flex-none'"
          @click="addMembers"
        >
          Add
        </kbutton>
      </div>
    </div>
  </template>
</modal>
