<template src="./ImportCustomFieldMapping.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels";
import AppLoader from "../../../../../common/AppLoader/AppLoader.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import AppSnackBar from "../../../../../common/AppSnackBar/AppSnackBar";
import loader from "../../../../../common/AppLoader/AppLoader";
import { importAudience } from "../../../../../../services/clubDetails/allAudience.service";
import modal from "../../../../../common/AppModal/AppModal.vue";
import SnackBarModel from "@/models/snackBar/snackBarModel";

export default {
  components: {
    Tooltip,
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    "k-form": Form,
    error: Error,
    "app-loader": AppLoader,
    dropdownlist: DropDownList,
    AppSnackBar,
    loader,
    modal,
  },
  props: {
    documentId: "",
    dialogue: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      mapConfig: [],
      clubId: this.$route.params.id,
      memberImportModalVisible: false,
      searchData: "",
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
      isLoader: false,
    };
  },
  computed: {
    columns() {
      return this.$store.state.members.columnList;
    },
    isColumnMetaLoading() {
      return this.$store.state.members.isColumnMetaLoading;
    },
    custom_fields() {
      return this.$store.state.allAudience.fields;
    },
    isLoadingModal() {
      return this.$store.state.allAudience.isLoadingModal;
    },
    modalVisible() {
      return this.$store.state.members.loader;
    },
    isImported() {
      return this.$store.state.members.isImported;
    },
    memberModalVisible() {
      return this.$store.state.members.memberModalVisible;
    },
  },
  mounted() {
    this.getColumns(this.documentId);
    let map = {
      label: "Email",
      column: "email",
      mapFrom: "",
      isRequired: true,
      errorMessage: "",
    };
    this.mapConfig.push(map);
    let map1 = {
      label: "Tags",
      column: "tags",
      mapFrom: "",
      isRequired: false,
      errorMessage: "",
    };
    this.mapConfig.push(map1);
    let custom_fields = this.custom_fields;
    for (let i in custom_fields) {
      let map = {
        label: custom_fields[i].label,
        column: custom_fields[i].label,
        mapFrom: "",
        isRequired: custom_fields[i].isRequired,
        errorMessage: "",
      };
      this.mapConfig.push(map);
    }
  },
  methods: {
    onOpen(e) {
      this.searchData = "";
      this.getColumns(this.documentId);
    },
    onClose() {
      this.searchData = "";
    },
    cancelModal() {
      this.$store.dispatch("importCloseModal");
      this.$emit("closeMemberMapping", true);
      this.$store.commit("IMPORT_MODAL_VISIBLE", false);
    },
    modalHandler() {
      this.$store.dispatch("importCloseModal");
      this.$emit("closeMemberMapping", true);
      this.$store.commit("IMPORT_MODAL_VISIBLE", false);
    },
    filterChange(event) {
      this.searchData = "";
      let data = this.$store.state.members.columnModifier;
      this.searchData = filterBy(data, event.filter);
      this.$store.commit("SET_COLUMNS", this.searchData);
    },
    filterData(filter) {
      const data = this.columns.slice();
      return filterBy(data, filter);
    },
    getColumns(id) {
      let payload = {
        clubId: this.$route.params.id,
        documentId: id,
      };
      this.$store.dispatch("getColumns", payload);
    },
    handleMap(item, e, r) {
      for (let i in this.mapConfig) {
        if (this.mapConfig[i].column === item) {
          this.mapConfig[i].mapFrom = e.value;
        }
      }
      for (let i in this.mapConfig) {
        if (this.mapConfig[i].column === item) {
          this.mapConfig[i].errorMessage = "";
        }
      }
      this.$store.commit(
        "SET_COLUMNS",
        this.$store.state.members.columnModifier
      );
    },
    saveData() {
      if (this.mapConfig.length == 0) {
        return;
      }
      this.isLoader = true;
      for (let i in this.mapConfig) {
        if (
          this.mapConfig[i].isRequired &&
          (this.mapConfig[i].mapFrom == "" ||
            this.mapConfig[i].mapFrom == "Select Column")
        ) {
          this.mapConfig[i].errorMessage = "Required";
          // return;
        }
      }
      for (let i in this.mapConfig) {
        if (this.mapConfig[i].errorMessage) {
          return;
        }
      }
      var mapConPayload = this.mapConfig.filter((x) => x.mapFrom);
      this.$store.commit("SET_LOADER", true);
      this.$store.commit("SET_LOADER_All_AUD_MODAL", true);
      let payload = {
        clubId: +this.clubId,
        documentId: this.documentId,
        mappingConfig: mapConPayload,
        entityType: +this.$route.query.type,
      };
      payload.refetchGrid = this.refetchGrid
      this.$store.dispatch("importAudience", payload);
    },
    refetchGrid(){
      this.$emit('refetchGridData',true);
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = "SSSSSSS"),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
  },
};
</script>
