<app-loader v-if="isLoaderLoading"></app-loader>
<app-loader v-if="loaderLoading"></app-loader>
<modal
  v-if="!loaderLoading"
  :title="modalName()"
  :handler="modalVisible || editModalVisible"
  :cssClass="'c-modal-small-2'"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <div class="k-form-field">
      <label>Field Label *</label>
      <k-input
        v-model="model.label"
        :placeholder="'Field Label'"
        @keyup="errors.Label[0] = ''"
      />
      <span class="k-form-error font-10-vsm"
        >{{ this.errors?.Label?.[0] }}</span
      >
    </div>
    <div class="k-form-field">
      <label>Type</label>
      <dropdownlist
        :style="{}"
        :data-items="fieldTypeValues"
        :text-field="'text'"
        :data-item-key="'id'"
        :value="model.fieldTypeObj"
        @change="handlePassStatusValue"
        :disabled="rowId ? true : false"
      >
      </dropdownlist>
    </div>

    <br />

    <div v-if="model.fieldTypeObj.id == 2" class="k-form-field">
      <label>Additional Options *</label>
      <multiselect
        :class="'hide-cross-disabled mb-24'"
        :style="{}"
        :data-items="model.options"
        :placeholder="'Select multiple'"
        :value="model.options"
        @change="additionalOptionsOnChange"
        :allow-custom="true"
        :disabled="rowId ? true : false"
      >
      </multiselect>
      <span class="k-form-error font-10-vsm"
        >{{this.errors?.Options?.[0]}}</span
      >
    </div>

    <div class="k-form-field">
      <checkbox
        v-model="model.isVisible"
        :default-checked="'true'"
        :label="'Visibility'"
      />
    </div>
    <div class="k-form-field mt-2">
      <checkbox
        v-model="model.isRequired"
        :default-checked="'true'"
        :label="'Required'"
      />
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <div class="me-1">
        <kbutton @click="cancelModal" :class="'flex-none'">Cancel</kbutton>
      </div>
      <div class="ms-1">
        <kbutton
          :theme-color="'primary'"
          @click="saveNewField"
          :class="'flex-none'"
          >{{rowId ? "Update" : "Save"}}
        </kbutton>
      </div>
    </div>
  </template>
</modal>
