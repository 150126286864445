<template src="./ConfigureFields.html"></template>
<style lang="scss">
@import "./ConfigureFields.scss";
</style>

<script>
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import Breadcrumb from "../../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import { Checkbox } from "@progress/kendo-vue-inputs";
import addNewFieldModal from "../modals/addNewFieldModal/AddNewFieldModal";
import AppSnackBar from "../../../../common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "../../../../common/AppLoader/AppLoader";
import CofigureFieldsEditBulkModel from "../../../../../models/audience/allAudience/CofigureFieldsEditBulkModel";
import ConfigureFieldDeleteModal from "../modals/configureFieldDeleteModal/ConfigureFieldDeleteModal";
import { bulkSave } from "../../../../../services/clubDetails/allAudience.service";
import AppPopUp from "@/components/common/AppPopUp/AppPopUp.vue";
import { POST } from '@/service';

export default {
  components: {
    Grid: Grid,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    dropdownlist: DropDownList,
    DropDownButton,
    checkbox: Checkbox,
    addNewFieldModal,
    AppSnackBar,
    Breadcrumb,
    AppLoader,
    ConfigureFieldDeleteModal,
    AppPopUp,
  },
  data() {
    var model = new CofigureFieldsEditBulkModel();
    return {
      itemTagTypes: {
        1: "dpAudience",
        2: "ecAudience",
        3: "vAudience",
      },
      moduleTagTypes: {
        1: "digitalPass",
        2: "emailCampaign",
        3: "voucher",
      },
      childItemCodeType: {
        1: "dpAllAudience",
        2: "ecAllAudience",
        3: "vAllAudience",
      },
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      valueType: "valueType",
      actions: "actions",
      visibility: "visibility",
      required: "required",
      isVisible: false,
      fieldrowId: "",
      vlueTypeDropDown: [
        { id: 0, text: "Text" },
        { id: 1, text: "Neumeric" },
        { id: 1, text: "Dropdown" },
      ],
      vlueTypeDropDownValue: { id: 0, text: "Text" },
      actionsDropDown: [],
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
      loader: false,
      model,
      bulk: [],
      fieldName: "",
      isLoading: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          field: "label",
          title: "Field Label",
        },
        {
          field: "fieldType",
          title: "Value Type",
          cell: "fieldType",
        },
        {
          field: "options",
          title: "Additional Options",
          cell: "options",
        },
        {
          field: "isVisible",
          title: "Visibility",
          cell: "visibility",
        },
        {
          field: "isRequired",
          title: "Required",
          cell: "required",
        },
        {
          field: "actions",
          title: "Actions",
          cell: "actionCell",
        },
      ];
    },
    fieldData() {
      return this.$store.state.allAudience.fields;
    },
    modalVisible() {
      return this.$store.state.allAudience.isAddNewFieldModal;
    },
    editModalVisible() {
      return this.$store.state.allAudience.isUpdateFieldModal;
    },
    isLoaderLoading() {
      return this.$store.state.allAudience.isLoading;
    },
    isDeleteModalVisible() {
      return this.$store.state.common.modalVisible;
    },
    isShowMsg() {
      return this.$store.state.common.showMsg;
    },
    showMsg() {
      return this.$store.state.common.status;
    },
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    this.actionsDropDown = [
      ...(this.hasEditAccess() ? [{ dropId: 2, text: "Edit" }] : []),
      ...(this.hasCreateAccess() ? [{ dropId: 0, text: "Duplicate" }] : []),
      ...(this.hasDeleteAccess() ? [{ dropId: 1, text: "Delete" }] : []),
    ];
    this.getAllFields();
  },
  methods: {
    updateLoader(value) {
      this.isLoading = value;
    },
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    onVisibleChange(e, item) {
      item.isVisible = e.value;
      var alreadyAdded = this.bulk?.find((x) => x.id == item.id);

      this.bulk = this.bulk.filter((x) => x.id != item.id);

      var model = new CofigureFieldsEditBulkModel();
      model.id = item.id;
      model.entityType = +this.$route.query.type;
      model.isVisible = e.value;
      model.isRequired = alreadyAdded
        ? alreadyAdded.isRequired
        : item.isRequired;

      this.bulk.push(model);
    },

    reload() {
      this.$store.commit("SET_CONFIGURE_FIELDS", []);
      var p = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
      };
      this.$store.dispatch("getConfigureFields", p);
    },

    onRequiredChange(e, item) {
      item.isRequired = e.value;
      var alreadyAdded = this.bulk?.find((x) => x.id == item.id);

      this.bulk = this.bulk.filter((x) => x.id != item.id);

      var model = new CofigureFieldsEditBulkModel();
      model.id = item.id;
      model.entityType = +this.$route.query.type;
      model.isVisible = alreadyAdded ? alreadyAdded?.isVisible : item.isVisible;
      model.isRequired = e.value;

      this.bulk.push(model);
    },

    getAllFields() {
      var payload = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
      };
      this.$store.dispatch("getConfigureFields", payload);
    },
    cancel() {
      // this.$emit("cancel");
      if (this.$route.query.type == 3) {
        this.$router.push({
          name: "voucherAllAudience",
          query: {
            type: +this.$route.query.type,
          },
        });
      } else {
        this.$router.push({
          name: "DpAllAudience",
          query: {
            type: +this.$route.query.type,
          },
        });
      }
    },
    save() {
      this.$emit("save");
    },
    saveBulk() {
      const payload = {
        clubId: this.$route.params.id,
        editBulkAudienceFieldList: this.bulk,
      };
      bulkSave(payload)
        .then((response) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Field updated successfully!",
            type: "success",
          });
          // let payload = {
          //   type: "success",
          //   Message: "Field updated successfully!",
          // };
          // this.$store.dispatch("showNotification", payload);

          var payload2 = {
            clubId: this.$route.params.id,
            entityType: +this.$route.query.type,
          };
          this.$store.dispatch("getConfigureFields", payload2);
        })
        .catch((error) => {});
    },
    handlePassStatusValue(e) {},
    openUpdateFieldsModal(e) {
      this.fieldrowId = e;
      this.$store.commit("SET_UPDATE_FIELD_OPEN", true);
    },
    handleChange(e) {
      this.isVisible = !this.isVisible;
    },
    save() {},
    openAddNewFieldsModal() {
      this.$store.commit("SET_ADD_NEW_FIELD_OPEN", true);
      // this.$store.commit("SET_LOADER", true);
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
    addNewFieldnotification(data) {
      this.showNotification(data.Message, data.type);
    },
    deleteFieldnotification(data) {
      this.showNotification(data.Message, data.type);
    },
    onItemClick(dropId, item) {
      this.fieldrowId = item.id;
      if (dropId == 1) {
        this.$store.commit("MODAL_VISIBLE_COMMON", true);
      }
      if (dropId == 0) {
        this.isLoading = true;
        var payload = {
          fieldId: +this.fieldrowId,
          clubId: this.$route.params.id,
          entityType: +this.$route.query.type,
          item
        };

        POST(`api/v1/Audience/fields/${this.fieldrowId}/clone`, payload)
          .then((response) => {
            this.isLoading = false;
            
            var payload = {
              clubId: this.$route.params.id,
              entityType: +this.$route.query.type,
            };

            this.$store.dispatch("getConfigureFields", payload);
            this.$store.dispatch("showNotification", {
              type: "success",
              Message: "Field duplicated successfully!",
            });
          })
          .catch((error) => {
            this.isLoading = false;
          });
      }

      if (dropId == 2) {
        this.openUpdateFieldsModal(item);
      }
    },
    onOpen(e) {
      this.fieldrowId = e.id;
      this.fieldName = e.label;
    },
    getValue(e) {
      return { id: 1, text: e };
    },
  },
};
</script>

<style></style>
