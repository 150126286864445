<template src="./SetStatusActionModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../common/AppModal/AppModal.vue";
import { removeTag } from "../../../../services/clubDetails/tag.service";

export default {
    components: {
        "k-dialog": Dialog,
        "dialog-actions-bar": DialogActionsBar,
        kbutton: Button,
        "k-input": Input,
        modal,
    },
    props: {
        ids: {
            type: Array,
            default: [],
        },
        selectedItems: {
            type: [],
            required: true,
        },
        actionType: {
            type: String,
            required: true,
        },
        onConfirm: {
            type: Function,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
    },
    computed: {},
    data: function () {
        return {
            visibleDialog: true,
            clubId: this.$route.params.id,
            isLoader: false,
        };
    },
    methods: {
        // deleteList() {
        //     this.isLoader = true;
        //     var mod = {
        //         ids: this.ids,
        //     };

        //     removeTag(mod)
        //         .then((response) => {
        //             this.isLoader = false;
        //             let payload = {
        //                 type: "success",
        //                 Message: "Selected tag has been deleted.",
        //             };
        //             this.$store.dispatch("showNotification", payload);

        //             var model = {
        //                 clubId: this.$route.params.id,
        //                 entityType: +this.$route.query.type,
        //                 searchQuery: " ",
        //             };
        //             this.$store.dispatch("getTags", model);
        //             this.$store.commit("ACTION_MODAL_VISIBLE", false);
        //         })
        //         .catch((error) => {
        //             this.isLoader = false;
        //         });
        // },
        confirmFunc() {
            alert("Coming soon");
        },
        modalHandler(data) {
            this.$store.commit("ACTION_MODAL_VISIBLE", false);
        },
    },
};
</script>
