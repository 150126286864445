import { DELETE, GET, POST, PUT } from "@/service";
import { useRoute } from "vue-router";

export const getColumns = (payload: any) => {
  payload.clubId = payload?.clubId ? payload?.clubId : useRoute()?.params?.id;
  return GET(`api/v1/Document/${payload.documentId}/meta`, {
    params: payload,
  });
};

export const getMembers = (payload: any) => {
  payload.clubId = payload?.clubId ? payload?.clubId : useRoute()?.params?.id;
  return GET(`api/v1/member/${payload.clubId}/members`, {
    params: payload,
  });
};

export const importMembers = (payload: any) => {
  payload.clubId = payload?.clubId ? payload?.clubId : useRoute()?.params?.id;
  return POST(`api/v1/member/${payload.clubId}/members/import/`, payload);
};

export const deleteMembers = (payload: any) => {
  payload.clubId = payload?.clubId ? payload?.clubId : useRoute()?.params?.id;
  return DELETE(`api/v1/member/${payload.clubId}/members/`, {
    data: payload,
  });
};

export const updateSubscriberStatusService = (payload: any) => {
  payload.clubId = payload?.clubId ? payload?.clubId : useRoute()?.params?.id;
  return PUT(
    `api/v1/member/${payload?.clubId}/members/update-subscriber-status`,
    payload
  );
};

export const getMembersColumnService = (payload: any) => {
  return GET(`api/v1/member/club-member-render-fields`, {
    params: payload,
  });
};

export const updateMembersColumnService = (payload: any) => {
  return PUT(
    `api/v1/member/${payload?.clubId}/update-status-club-member-render-fields`,
    payload
  );
};
export const getListsService = (payload: any) => {
  return GET(`api/v1/Audience/profile/get-all-existed-lists`, {
    params: payload,
  });
};
export const saveToListService = (payload: any) => {
  return POST(`api/v1/Audience/profile/${payload?.clubUserId}/add-to-list`, payload)
}

