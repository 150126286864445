<template src="./AllAudienceDeleteModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../../common/AppModal/AppModal";
import AppLoader from "../../../../../common/AppLoader/AppLoader";
import AppSnackBar from "../../../../../common/AppSnackBar/AppSnackBar.vue";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
    AppLoader,
    AppSnackBar,
  },
  props: {
    selectedItems: {
      type: [],
      required: true,
    },

    tagId: {
      type: Number,
      required: null,
    },

    listId: {
      type: Number,
      required: null,
    },
  },
  computed: {
    modalVisible() {
      return this.$store.state.common.modalVisible;
    },
    club() {
      return this.$store.getters.clubDetails;
    },
    isLoader() {
      return this.$store.state.allAudience.isLoading;
    },
    isLoading() {
      return this.$store.state.allAudience.isLoadingModal;
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      isNotify: false,
      notifyMessage: "Success",
      notifyType: "success",
    };
  },
  methods: {
    cancelModal() {
      this.$store.commit("MODAL_VISIBLE_COMMON", false);
    },
    deleteClub() {
      this.$store.commit("SET_LOADER_All_AUD_MODAL", true);
      let payload = {
        clubId: this.clubId,
        entityType: this.$route.query.type,
        ids: this.selectedItems,
        tagId: this.tagId,
        listId: this.listId,
      };

      // this.$store.dispatch("deleteAudienceManager", payload);
      this.$emit("deleteHandler", payload);
      this.$emit("allcheckedHandle", false);
      this.$emit("selectedLength", 0);
    },
    modalHandler(data) {
      this.$store.commit("MODAL_VISIBLE_COMMON", false);
    },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },
  },
};
</script>
