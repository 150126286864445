<template src="./AddAudienceModal.html"></template>
<style lang="scss">
@import "./AddAudienceModal.scss";
</style>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels";
import AppLoader from "../../../../../common/AppLoader/AppLoader.vue";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import modal from "../../../../../common/AppModal/AppModal.vue";
import GetMembersModel from "../../../../../../models/member/getMembersModel";
import AppSnackBar from "../../../../../common/AppSnackBar/AppSnackBar";
import { addMembersToTemplateService } from "../../../../../../services/clubDetails/manageRecipients.service";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { Log } from "oidc-client";
import AddAudienceMemberModel from "@/models/audience/allAudience/AddAudienceMemberModel";
import { addAudienceMember } from "../../../../../../services/clubDetails/allAudience.service";
import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";
import {
  getMembersColumnService,
} from "@/services/clubDetails/member.service";
import moment from "moment/moment";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    checkbox: Checkbox,
    "k-form": Form,
    error: Error,
    "app-loader": AppLoader,
    Grid: Grid,
    toolbar: GridToolbar,
    modal,
    AppSnackBar,
    GridNoRecords,
  },
  data() {
    var model = new AddAudienceMemberModel();
    return {
      clubId: this.$route.params.id,
      searchWord: "",
      selectedField: "selected",
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      selectedItems: [],
      rejectedItems: [],
      memberColumns: [],
      isColumnLoading: false,
      dateCells: [],
      allColumns: [],
      sort: [],
      sortBy: "",
      sortDir: "",
      memberFilterPopupVisibility: false,
      importModalVisible: false,
      visibleDialog: true,
      finalItems: [],
      isNotify: false,
      notifyMessage: "Success",
      notifyType: "success",
      forceSelectAll: false,
      allChecked: false,
      model,
      timer: null,
    };
  },

  computed: {
    areAllSelected() {
      if (this.forceSelectAll) {
        return true;
      } else {
        return (
          this.selectedItems?.length == this.membersData?.items?.length &&
          this.membersData?.items?.length != 0
        );
      }
    },
    isShowMsg() {
      return this.$store.state.common.showMsg;
    },
    showMsg() {
      return this.$store.state.common.status;
    },
    columns() {
      return [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
          width: "50px",
        },
        ...this?.memberColumns,
      ]
    },
    membersData() {
      if (this.forceSelectAll) {
        let membersData = this.$store.state.members.members.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.rejectedItems.indexOf(membersData[key].clubUserId) !== -1) {
              membersData[key].selected = false;
            } else {
              membersData[key].selected = true;
            }
          }
        }

        return this.$store.state.members.members;
      } else {
        let membersData = this.$store.state.members.members.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].clubUserId) !== -1) {
              membersData[key].selected = true;
            } else {
              membersData[key].selected = false;
            }
          }
        }
        return this.$store.state.members.members;
      }
    },
    total() {
      return this.membersData ? this.membersData.size : 0;
    },
    take() {
      return this.membersData ? this.membersData.limit : 0;
    },
    skip() {
      return this.membersData ? this.membersData.offset : 0;
    },
    isSearching() {
      return this.$store.state.members.searchQuery;
    },
    visibleModal() {
      return this.$store.state.club.modalVisible;
    },
    isLoading() {
      return this.$store.state.members.loading;
    },
  },
  mounted() {
    this.$store.commit("SET_MEMBERS", []);
    this.$store.commit("SET_ITEMS", []);
    this.$store.commit("SET_LOADER", true);
    this.getMembers();
    this.getColumns();
  },
  methods: {
    // selectAllLabelGenerator() {
    //   return this.forceSelectAll
    //     ? `Select All (${this.membersData?.size - this.rejectedItems?.length})`
    //     : "Select All (0)";
    // },
    selectAllLabelGenerator() {
      if (this.forceSelectAll) {
        return `Select All (${this.membersData.size - this.rejectedItems.length})`;
      } else {
        return `Select All (${this.selectedItems.length})`;
      }
    },
    getColumns() {
      this.isColumnLoading = true;
      getMembersColumnService({ clubId: this.clubId })
          .then((res) => {
            this.isColumnLoading = false;
            this.allColumns = res.data;
            this.memberColumns = this.culumnGenerator(res.data);
          })
          .catch((err) => {
            this.isColumnLoading = false;
          });
    },
    culumnGenerator(data) {
      let columns = [];

      data.forEach((element) => {
        if (element.isChecked) {
          if (["Name", "Status"].includes(element.title)) {
            columns.push({
              field: element.field,
              title: element.title,
              cell: element.field + "Cell",
              sortable: element?.sortable,
            });
          } else {
            if (element.type == 4) {
              columns.push({
                id: element.id,
                field: element.field,
                title: element.title,
                cell: element.field + "Cell",
                sortable: element?.sortable,
              });

              this.dateCells.push({
                field: element.field,
                title: element.title,
                cell: element.field + "Cell",
              });
            } else {
              columns.push({
                id: element.id,
                field: element.field,
                title: element.title,
                sortable: element?.sortable,
              });
            }
          }
        }
      });
      return columns;
    },
    payloadGenerator() {
      let payload = new GetAudienceModel();
      payload = {
        ...payload,
        clubId: this.clubId,
        searchText: this.searchWord,
      };

      if (this.sortBy && this.sortDir) {
        payload.sort = this.sortBy;
        payload.order = this.sortDir;
      }

      return payload;
    },
    refetchMembers(resetPagination = false) {
      let payload = this.payloadGenerator();

      if (resetPagination) {
        payload = {
          ...payload,
          limit: this.take,
          offset: 0,
        };
      } else {
        payload = {
          ...payload,
          limit: this.take,
          offset: this.skip,
        };
      }

      this.$store.dispatch("getAudience", payload);
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }

      this.refetchMembers(true);
    },
    getDateLocal(date) {
      if (date != null) {
        return moment(date).format("DD-MMM-YYYY");
      } else {
        return "-";
      }
    },
    getMembers() {
      let payload = {
        clubId: this.clubId,
        entityType: +this.$route.query.type,
        searchText: "",
        exceptAudiences: true,
      };
      this.$store.dispatch("getMembers", payload);
    },

    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    onHeaderSelectionChange(event) {
      this.allChecked = event.event.target.checked;
      this.membersData.items = this.membersData.items.map((item) => {
        return { ...item, selected: this.allChecked };
      });
      if (this.allChecked) {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].clubUserId) !== -1) {
            } else {
              this.selectedItems.push(membersData[key].clubUserId);
            }
          }
        }
      } else {
        let membersData = this.membersData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].clubUserId) !== -1) {
              let selectedData = this.selectedItems;
              for (const j in selectedData) {
                if (Object.hasOwnProperty.call(selectedData, j)) {
                  if (selectedData[j] === membersData[key].clubUserId) {
                    selectedData.splice(j, 1);
                  }
                }
              }
            }
          }
        }
      }
    },
    addMembers() {
      this.$store.commit("SET_LOADER_All_AUD", true);
      this.model.entityType = +this.$route.query.type;
      this.model.clubId = this.$route.params.id;
      this.model.clubUserIds = this.selectedItems;
      this.model.isAll = this.forceSelectAll;
      this.model.exceptClubUserIds = this.rejectedItems;

      addAudienceMember(this.model)
        .then((response) => {
          this.$store.commit("SET_LOADER_All_AUD", false);

          var model = new GetAudienceModel();
          model.clubId = this.$route.params.id;
          model.entityType = +this.$route.query.type;
          model.searchQuery = "";
          model.limit = 100;
          this.$store.dispatch("getAudience", model);

          var modelMsg = new SnackBarModel();
          modelMsg.type = "success";
          modelMsg.Class = "status-error";
          modelMsg.Message = "Selected audiences have been added to the list.";
          this.$store.commit("SET_SHOW_MESSAGE", true);
          this.$store.dispatch("showNotification", modelMsg);
        })
        .catch((error) => {
          ;
        });
      this.$store.commit("MODAL_VISIBLE", false);
      this.$emit("closeSelectMemberModal");
      this.$emit("closeSelectMemberModalAdd");
    },
    modalHandler(data) {
      this.$store.commit("MODAL_VISIBLE", false);
      this.$emit("closeSelectMemberModal");
      this.$emit("closeSelectMemberModalAdd");
    },

    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      if (this.forceSelectAll) {
        if (event.dataItem.selected === false) {
          this.rejectedItems.push(event.dataItem.clubUserId);
        } else {
          if (this.rejectedItems.indexOf(event.dataItem.clubUserId) !== -1) {
            let rejectedData = this.rejectedItems;
            for (const key in rejectedData) {
              if (Object.hasOwnProperty.call(rejectedData, key)) {
                if (rejectedData[key] === event.dataItem.clubUserId) {
                  rejectedData.splice(key, 1);
                }
              }
            }
            ;
            this.rejectedItems = rejectedData;
          }
        }
      } else {
        if (event.dataItem.selected === true) {
          this.selectedItems.push(event.dataItem.clubUserId);
        } else {
          if (this.selectedItems.indexOf(event.dataItem.clubUserId) !== -1) {
            let selectedData = this.selectedItems;
            for (const key in selectedData) {
              if (Object.hasOwnProperty.call(selectedData, key)) {
                ;
                ;
                if (selectedData[key] === event.dataItem.clubUserId) {
                  selectedData.splice(key, 1);
                }
              }
            }
            ;
            this.selectedItems = selectedData;
          }
        }
      }
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.onSearch(event);
      }, 600);
    },
    onSearch(e) {
      this.searchWord = e.value;
      this.$store.commit("SET_LOADER", true);

      let payload = {
        clubId: this.clubId,
        limit: this.take,
        entityType: +this.$route.query.type,
        searchText: this.searchWord,
        exceptAudiences: true,
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("getMembers", payload);
      }, 800);
    },
    dataStateChange: function (event) {
      this.$store.commit("SET_LOADER", true);
      this.allChecked = false;
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = {
        clubId: this.clubId,
        entityType: +this.$route.query.type,
        searchText: this.searchWord,
        exceptAudiences: true,
        limit: event.data.take,
        offset: event.data.skip,
      };

      this.$store.dispatch("getMembers", payload);
    },
    deleteMember() {
      let payload = {
        clubId: this.$props.id,
        userIds: this.selectedItems,
      };
      this.$store.dispatch("deleteMembers", payload);
      this.selectedItems = [];
    },
  },
};
</script>

<style></style>
