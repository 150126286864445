<app-loader v-if="modalVisible || isColumnMetaLoading"></app-loader>
<modal
  title="Map Data to Audience Custom Fields"
  :handler="memberModalVisible"
  :isLoading="isLoadingModal"
  :cssClass="'modal-md-3'"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <div class="border-normal-1 radius-8 mb-16">
      <div class="px-15 px-7-sm bg-gray-4 radius-8">
        <div class="row mb-3 p-12">
          <div class="col-6 d-flex align-items-center ps-1">
            <span class="pe-1 d-block font-13 font-w-500 text-color-2"
              >Custom Fields</span
            >
            <!-- <kbutton :fill-mode="'flat'" :class="'p-0'">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.39043 13.512C8.19027 13.7622 7.80973 13.7622 7.60957 13.512L4.64988 9.81235C4.38797 9.48497 4.62106 9 5.04031 9H10.9597C11.3789 9 11.612 9.48497 11.3501 9.81235L8.39043 13.512Z"
                  fill="#A1A9B8"
                />
                <path
                  d="M8.39043 2.48804C8.19027 2.23784 7.80973 2.23784 7.60957 2.48804L4.64988 6.18765C4.38797 6.51503 4.62106 7 5.04031 7H10.9597C11.3789 7 11.612 6.51503 11.3501 6.18765L8.39043 2.48804Z"
                  fill="#A1A9B8"
                />
              </svg>
            </kbutton> -->
            <!-- <Tooltip
              :open-delay="100"
              :position="'top'"
              :anchor-element="'target'"
              :className="'import-member-tooltip'"
            >
              <div :style="{'text-align': 'center'}">
                <span
                  class="k-icon k-i-information"
                  title="The system columns are general information of a member required to be registered on system."
                ></span>
              </div>
            </Tooltip> -->
          </div>
          <div class="col-6 d-flex align-items-center">
            <span class="pe-1 d-block font-13 font-w-500 text-color-2"
              >Columns in your files</span
            >
            <!-- <kbutton :fill-mode="'flat'" :class="'p-0'">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.39043 13.512C8.19027 13.7622 7.80973 13.7622 7.60957 13.512L4.64988 9.81235C4.38797 9.48497 4.62106 9 5.04031 9H10.9597C11.3789 9 11.612 9.48497 11.3501 9.81235L8.39043 13.512Z"
                  fill="#A1A9B8"
                />
                <path
                  d="M8.39043 2.48804C8.19027 2.23784 7.80973 2.23784 7.60957 2.48804L4.64988 6.18765C4.38797 6.51503 4.62106 7 5.04031 7H10.9597C11.3789 7 11.612 6.51503 11.3501 6.18765L8.39043 2.48804Z"
                  fill="#A1A9B8"
                />
              </svg>
            </kbutton> -->
            <!-- <Tooltip
              :open-delay="100"
              :position="'top'"
              :anchor-element="'target'"
              :className="'import-member-tooltip'"
            >
              <div :style="{'text-align': 'center'}">
                <span
                  class="k-icon k-i-information"
                  title="Select the columns from your uploaded file in order to map data to system columns."
                ></span>
              </div>
            </Tooltip> -->
          </div>
        </div>
      </div>

      <div class="px-15 radius-8">
        <div
          v-for="(item, index) in mapConfig"
          :key="index"
          class="row text-start border-top-normal-1 py-12 px-16"
        >
          <div class="mb-0 col-6 ps-0 align-self-center">
            <label v-if="item.isRequired" class="font-14 font-w-500 mb-0"
              >{{item.label}}*</label
            >
            <label v-else class="font-14 font-w-500">{{item.label}}</label>
          </div>
          <!-- @filter="filter" -->
          <div class="col-6 pe-0">
            <div class="form-field">
              <dropdownlist
                @open="onOpen($event)"
                @close="onClose"
                :class="'mb-0'"
                :data-items="columns"
                :filterable="true"
                @filterchange="filterChange"
                :value="custom_fields[key]"
                @change="handleMap(item.column, $event, item.isRequired)"
                :default-item="'Select Column'"
              >
              </dropdownlist>
              <div
                v-if="item.isRequired && item.errorMessage != ''"
                class="error-msg mt-1 ms-1 mb-0"
              >
                {{item.errorMessage}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <app-loader v-if="loading" class="form-spinner"></app-loader>
      <div class="me-1">
        <kbutton @click="cancelModal" :class="'flex-none'">Cancel</kbutton>
      </div>
      <div class="ms-1">
        <kbutton :theme-color="'primary'" @click="saveData" :class="'flex-none'"
          >Import Data</kbutton
        >
      </div>
    </div>
  </template>
</modal>
