<template src="./AllAudience.html"></template>
<style lang="scss">
@import "./AllAudience.scss";
</style>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import AddAudience from "./modals/addAudienceModal/AddAudienceModal";
import ImportDataModal from "./modals/importDataModal/ImportDataModal";
import importCustomFieldMapping from "./modals/importCustomFieldMapping/ImportCustomFieldMapping.vue";
import ConfigureFields from "./configureFields/ConfigureFields.vue";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";
import AllAudienceDeleteModal from "./modals/allAudienceDeleteModal/AllAudienceDeleteModal";
import axios from "axios";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar";
import NoDataRender from "../../../common/NoDataRender/NoDataRender.vue";
import AppLoader from "../../../common/AppLoader/AppLoader";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { configuration } from "@/configurationProvider";
import { auth } from "@/services/auth.service";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import SetStatusActionModal from "../../members/setStatusActionModal/SetStatusActionModal.vue";
import {
  updateAudienceSubscriberStatusService,
  getAllAudienceColumnService,
  updateAudienceColumnService,
} from "@/services/clubDetails/allAudience.service";
import { Popup } from "@progress/kendo-vue-popup";
import vClickOutside from "click-outside-vue3";
import { Checkbox } from "@progress/kendo-vue-inputs";
import moment from "moment";
export default {
  name: "Tags",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Grid: Grid,
    buttongroup: ButtonGroup,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    dropdownlist: DropDownList,
    DropDownButton,
    Breadcrumb,
    AddAudience,
    ImportDataModal,
    importCustomFieldMapping,
    ConfigureFields,
    AllAudienceDeleteModal,
    AppSnackBar,
    AppLoader,
    GridNoRecords,
    Tooltip,
    custom: NoDataRender,
    SetStatusActionModal,
    Popup,
    checkbox: Checkbox,
  },
  data() {
    return {
      columnFilterPopupVisibility: false,
      isColumnLoading: false,
      checkedColumns: [],
      dateCells: [],
      allColumns: [],
      sort: [],
      sortBy: "",
      sortDir: "",
      selectedStatus: { text: "All", id: 0 },
      statusList: [
        { text: "All", id: 0 },
        { text: "Subscribed", id: 2 },
        { text: "Unsubscribed", id: 3 },
        { text: "Non-subscribed", id: 1 },
        { text: "Cleaned", id: 4 },
        // { text: "Scheduled", id: 7 },
      ],

      itemTagTypes: {
        1: "dpAudience",
        2: "ecAudience",
        3: "vAudience",
      },
      moduleTagTypes: {
        1: "digitalPass",
        2: "emailCampaign",
        3: "voucher",
      },
      childItemCodeType: {
        1: "dpAllAudience",
        2: "ecAllAudience",
        3: "vAllAudience",
      },
      loader: false,
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      actionDropDown: {
        0: [
          { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          // { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
        1: [
          // { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
        2: [
          { id: 1, text: "Subscribed" },
          // { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
        3: [
          { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          // { id: 3, text: "Cleaned" },
        ],
        4: [
          { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
      },
      actionType: "",
      actionWarningMessage: "",
      listNoDataRender: "myTemplate",
      clubId: this.$route.params.id,
      searchWord: "",
      selectedField: "selected",
      importModalVisible: false,
      AddAudienceModalVisible: false,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      selectedItems: [],
      documentIdPass: "",
      selectedMembers: [],
      selectedMemberList: [],
      audienceDropDown: [{ id: 0, text: "All" }],
      audienceTagDropDown: [{ id: 0, text: "All" }],
      drodownImportExport: [],

      drodownNoAudImportExport: [
        { id: 0, text: "Import Data" },
        // { id: 1, text: "Export Audience" },
      ],
      passListStatusValue: { id: 0, text: "All" },
      passTagStatusValue: { id: 0, text: "All" },
      passSegmentStatusValue: { id: 0, text: "All" },
      openMembermapping: false,
      modalVisible: false,
      selectedUserStatus: 4,
      passList: [],

      closeMemberImport: true,
      importedDocId: "",
      isOpenConfigureFields: false,
      pramsTagId: null,
      pramsSegmentId: null,
      dataHave: [],
      allChecked: false,
      timer: null,
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
      isSearching: false,
      isTagID: false,
      forceSelectAll: false,
      isSegmentFilter: false,
    };
  },
  computed: {
    areAllSelected() {
      return (
        this.selectedItems?.length === this.allAudienceData.items?.length &&
        this.allAudienceData.items?.length != 0
      );
    },
    allAudienceData() {
      if (this.forceSelectAll) {
        let membersData = this.$store.state.allAudience.audiences.items;

        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.rejectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = false;
            } else {
              membersData[key].selected = true;
            }
          }
        }
        return this.$store.state.allAudience.audiences;
      } else {
        let membersData = this.$store.state.allAudience.audiences.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = true;
            } else {
              membersData[key].selected = false;
            }
          }
        }
        return this.$store.state.allAudience.audiences;
      }
    },
    fieldData() {
      return this.$store.state.allAudience.fields;
    },
    total() {
      return this.allAudienceData ? this.allAudienceData.size : 0;
    },
    take() {
      return this.allAudienceData ? this.allAudienceData.limit || 100 : 100;
    },
    skip() {
      return this.allAudienceData ? this.allAudienceData.offset : 0;
    },
    visibleModal() {
      return this.$store.state.club.modalVisible;
    },
    listData() {
      return this.$store.state.list.lists;
    },
    tagsData() {
      return this.$store.state.tag.tags;
    },
    segmentsData() {
      return this.$store.state.segments.segmentsLists;
    },
    deleteModalVisible() {
      return this.$store.state.common.modalVisible;
    },
    isShowMsg() {
      return this.$store.state.common.showMsg;
    },
    showMsg() {
      return this.$store.state.common.status;
    },
    isLoading() {
      return this.$store.state.allAudience.isLoading;
    },
    fetchController() {
      return this.$store.state.allAudience.fetchController;
    },
    isShowSuccessMsg() {
      return this.$store.state.allAudience.showSuccessMsg;
    },
    showSuccessMsg() {
      return this.$store.state.allAudience.successStatus;
    },

    isShowErrorMsg() {
      return this.$store.state.allAudience.showErrorMsg;
    },
    showErrorMsg() {
      return this.$store.state.allAudience.errorStatus;
    },

    isShowImportMsg() {
      return this.$store.state.allAudience.showImportMsg;
    },
    showImportMsg() {
      return this.$store.state.allAudience.importStatus;
    },
    actionmodalVisible() {
      return this.$store.state.members.actionModalVisible;
    },
    columns() {
      return [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
          width: "50px"
        },
        ...this.checkedColumns,
      ];
    },
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    this.$store.commit("SET_ALL_AUDIENCE", []);
    this.$store.commit("SET_ERROR_MESSAGE_ISSHOW", false);
    this.$store.commit("SET_SUCCESS_MESSAGE_ISSHOW", false);
    this.drodownImportExport = [
      ...(this.hasCreateAccess() || this.hasEditAccess()
        ? [{ id: 0, text: "Import Data" }]
        : []),
      ...(this.hasCreateAccess() ? [{ id: 1, text: "Export Audience" }] : []),
    ];
    this.selectedItems = [];
    if (+this.$route.query.tagId) {
      this.isTagID = true;
      let tagparam = {
        id: this.$route.query.tagId,
        text: this.$route.query.tagText,
      };
      this.passTagStatusValue = tagparam;
      this.pramsTagId = this.$route.query.tagId;
    }

    if (+this.$route.query.segmentId) {
      this.isTagID = true;
      this.isSegmentFilter = true;
      let segmentParam = {
        id: this.$route.query.segmentId,
        text: this.$route.query.segmentName,
      };
      this.passSegmentStatusValue = segmentParam;
      this.pramsSegmentId = this.$route.query.segmentId;
    }
    this.part;
    this.getColumns();
    this.getAudience();
    this.getAllList();
    this.getTags();
    if (+this.$route.query.type == 2) this.getSegmentsLists();
  },
  unmounted() {
    this.$store.commit("SET_ALL_AUDIENCE", []);
    this.$store.commit("SET_ERROR_MESSAGE_ISSHOW", false);
    this.$store.commit("SET_SUCCESS_MESSAGE_ISSHOW", false);
    this.selectedItems = [];
  },
  watch: {
    "$route.query.type"() {
      this.selectedItems = [];
      if (!this.hasGetAccess()) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: "You do not have access to view campaigns.",
          type: "warning",
        });
        this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
      }
      this.drodownImportExport = [
        ...(this.hasCreateAccess() || this.hasEditAccess()
          ? [{ id: 0, text: "Import Data" }]
          : []),
        ...(this.hasCreateAccess() ? [{ id: 1, text: "Export Audience" }] : []),
      ];
      this.getColumns();
      this.getAudience();
      this.getAllList();
      this.getTags();
    },
    "$route.query.key"() {
      this.passListStatusValue = { id: 0, text: "All" };
      this.passTagStatusValue = { id: 0, text: "All" };
      this.passSegmentStatusValue = { id: 0, text: "All" };
      this.searchWord = "";
    },
  },
  methods: {
    deleteHandler(payload) {
      let modpayload = {
        deletePayload: payload,
        refetchPayload: this.payloadGenerator(),
      };
      this.$store.dispatch("deleteAudienceManager", modpayload);
    },
    routeToMemberDetails(memberInfo) {
      let type = +this.$route.query.type;
      let audienceId = memberInfo.id;

      if (type == 1)
        this.$router.push(
          "/club-details/" +
            this.clubId +
            "/digitalpass-allAudience" +
            "/audience-details/" +
            memberInfo?.clubUserId +
            "?type=" +
            type +
            "&audienceId=" +
            audienceId
        );
      else if (type == 2)
        this.$router.push(
          "/club-details/" +
            this.clubId +
            "/campaign-allAudience" +
            "/audience-details/" +
            memberInfo?.clubUserId +
            "?type=" +
            type +
            "&audienceId=" +
            audienceId
        );
      else if (type == 3)
        this.$router.push(
          "/club-details/" +
            this.clubId +
            "/voucher-allAudience" +
            "/audience-details/" +
            memberInfo?.clubUserId +
            "?type=" +
            type +
            "&audienceId=" +
            audienceId
        );
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }

      this.refetchGridData(true);
    },
    onColumnSelectionChange(value, index) {
      this.allColumns[index].isChecked = value;
      this.updateColumns(
        {
          id: this.allColumns[index].id,
          isChecked: value,
        },
        index
      );
    },
    updateColumns(columnData, index) {
      this.isColumnLoading = true;
      let payload = {
        clubId: this.clubId,
        entityType: +this.$route.query.type,
        ...columnData,
      };
      updateAudienceColumnService(payload)
        .then((res) => {
          this.isColumnLoading = false;
          this.checkedColumns = this.culumnGenerator(this.allColumns);
        })
        .catch((error) => {
          this.isColumnLoading = false;
          this.allColumns[index].isChecked = !columnData.isChecked;
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              error.response.data?.Status?.Message ||
              "An error occurred while updating the columns. Please try again later.",
            type: "error",
          });
        });
    },
    culumnGenerator(data) {
      let columns = [];

      data.forEach((element) => {
        if (element.isChecked) {
          if (["Name", "Status"].includes(element.title)) {
            columns.push({
              field: element.field,
              title: element.title,
              cell: element.field + "Cell",
              sortable: element?.sortable,
              width: element.title == "Status" ? "150px" : "",
            });
          } else {
            if (element.type == 4) {
              columns.push({
                id: element.id,
                field: element.field,
                title: element.title,
                cell: element.field + "Cell",
                sortable: element?.sortable,
                width: "150px",
              });

              this.dateCells.push({
                field: element.field,
                title: element.title,
                cell: element.field + "Cell",
              });
            } else {
              columns.push({
                id: element.id,
                field: element.field,
                title: element.title,
                sortable: element?.sortable,
              });
            }
          }
        }
      });

      return columns;
    },
    getColumns() {
      this.isColumnLoading = true;
      getAllAudienceColumnService({
        clubId: this.clubId,
        entityType: this.$route.query.type,
      })
        .then((res) => {
          this.isColumnLoading = false;
          this.allColumns = res.data;
          this.checkedColumns = this.culumnGenerator(res.data);
        })
        .catch((err) => {
          this.isColumnLoading = false;
        });
    },
    payloadGenerator() {
      let payload = new GetAudienceModel();
      payload = {
        ...payload,
        clubId: this.clubId,
        entityType: +this.$route.query.type,
        searchQuery: this.searchWord,
        ...(this.selectedStatus.id != 0 && {
          subscriberStatusFilter: this.selectedStatus.id,
        }),
        // tagId: this.pramsTagId ? this.pramsTagId : this.passTagStatusValue?.id,
        tagId: this.passTagStatusValue?.id,
        segmentId: this.passSegmentStatusValue?.id,
        listId: this.passListStatusValue.id,
      };

      if (this.sortBy && this.sortDir) {
        payload.orderBy = `${this.sortBy} ${this.sortDir}`;
      }
      return payload;
    },
    refetchGridData(resetPagination = false) {
      let payload = this.payloadGenerator();
      if (resetPagination) {
        payload = {
          ...payload,
          limit: this.take,
          offset: 0,
        };
      }
      this.$store.dispatch("getAudience", payload);
    },
    handleValueChange(value, field) {
      this[field] = value;
      this.refetchGridData(true);
    },
    columnFilterHandler() {
      this.columnFilterPopupVisibility = !this.columnFilterPopupVisibility;
    },
    getDateLocal(date) {
      if (date != null) {
        return moment(date).format("DD-MMM-YYYY");
      } else {
        return "-";
      }
    },

    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },

    allcheckedHandle(val) {
      this.allChecked = false;
    },
    getTagFormat(props) {
      return props.dataItem.Tags.split(",", 2);
    },
    getAudience() {
      this.refetchGridData(true);
    },
    getAllList() {
      const model = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
        searchQuery: "",
      };
      this.$store.dispatch("getLists", model);
    },
    getTags() {
      const model = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
        searchQuery: " ",
      };
      this.$store.dispatch("getTags", model);
    },
    dropdownlistDatapass() {
      const dropdownlist = [{ id: 0, text: "All" }];
      for (const i in this.listData.items) {
        let object = {
          id: this.listData.items[i].id,
          text: this.listData.items[i].name,
        };
        dropdownlist.push(object);
      }
      return dropdownlist;
    },
    dropdownSegmentsDatapass() {
      const dropdownSegments = [{ id: 0, text: "All" }];
      for (const i in this.segmentsData.items) {
        let object = {
          id: this.segmentsData.items[i].id,
          text: this.segmentsData.items[i].segmentName,
        };
        dropdownSegments.push(object);
      }
      return dropdownSegments;
    },
    onActionClick(e) {
      if (e?.item?.id == 0) {
        this.actionType = 0;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} audience(s) to non-subscribe. Are you sure you want to proceed with this action?`;
      }
      if (e?.item?.id == 1) {
        this.actionType = 1;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} audience(s) to subscribe. Are you sure you want to proceed with this action?`;
      }
      if (e?.item?.id == 2) {
        this.actionType = 2;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} audience(s) to unsubscribe. Are you sure you want to proceed with this action?`;
      }
      if (e?.item?.id == 3) {
        this.actionType = 3;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} audience(s)  to cleaned. Are you sure you want to proceed with this action?`;
      }
      // ;
      const timerId = setTimeout(() => {
        this.$store.commit("ACTION_MODAL_VISIBLE", true);
        clearTimeout(timerId);
      }, 100);
    },
    onConfirmActionHandler() {
      this.$store.commit("ACTION_MODAL_VISIBLE", false);
      const payload = {
        clubId: this.$route.params.id,
        ids: this.selectedItems,
        entityType: +this.$route.query.type,
        subscriberStatus: this.actionType,
      };
      this.loader = true;
      updateAudienceSubscriberStatusService(payload)
        .then((res) => {
          this.selectedItems = [];
          this.loader = false;
          this.showNotification(
            `The subscription status of the selected audience(s) has been successfully updated.`,
            "success"
          );
          this.getAudience();
        })
        .catch((err) => {
          this.loader = false;
          this.showNotification(
            `An error occurred while updating the subscription status of the selected audience(s). Please try again later.'.`,
            "error"
          );
          // ;
        });
    },
    dropdownTagDatapass() {
      this.isSearching = true;
      const dropdownTag = [{ id: 0, text: "All" }];
      for (const i in this.tagsData.items) {
        if (
          this.audienceTagDropDown.length ==
          this.tagsData?.items?.length + 1
        ) {
          return this.audienceTagDropDown;
        }
        let object = {
          id: this.tagsData.items[i].id,
          text: this.tagsData.items[i].name,
        };
        dropdownTag.push(object);
      }
      return dropdownTag;
    },
    allEqualChecker(arr) {
      return arr.every(
        (val) => val?.subscriberStatus === arr[0]?.subscriberStatus
      );
    },

    userSubscribedStatusHandler() {
      const selectedArray = this.allAudienceData?.items?.filter(
        (item) => item.selected
      );
      if (this.allEqualChecker(selectedArray)) {
        this.selectedUserStatus = selectedArray[0]?.subscriberStatus;
      } else {
        this.selectedUserStatus = 4;
      }
    },
    onHeaderSelectionChange(event) {
      this.selectedUserStatus = 4;
      this.allChecked = event.event.target.checked;
      this.allAudienceData.items = this.allAudienceData.items.map((item) => {
        return { ...item, selected: this.allChecked };
      });
      if (this.allChecked) {
        let membersData = this.allAudienceData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
            } else {
              this.selectedItems.push(membersData[key].id);
            }
          }
        }
      } else {
        let membersData = this.allAudienceData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              let selectedData = this.selectedItems;
              for (const j in selectedData) {
                if (Object.hasOwnProperty.call(selectedData, j)) {
                  if (selectedData[j] === membersData[key].id) {
                    selectedData.splice(j, 1);
                  }
                }
              }
            }
          }
        }
      }
      this.userSubscribedStatusHandler();
    },
    onSelectionChange(event) {
      this.selectedUserStatus = event?.dataItem?.subscriberStatus;
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      if (this.forceSelectAll) {
        if (event.dataItem.selected === false) {
          this.rejectedItems.push(event.dataItem.id);
        } else {
          if (this.rejectedItems.indexOf(event.dataItem.id) !== -1) {
            let rejectedData = this.rejectedItems;
            for (const key in rejectedData) {
              if (Object.hasOwnProperty.call(rejectedData, key)) {
                if (rejectedData[key] === event.dataItem.id) {
                  rejectedData.splice(key, 1);
                }
              }
            }
            this.rejectedItems = rejectedData;
          }
        }
      } else {
        if (event.dataItem.selected === true) {
          this.selectedItems.push(event.dataItem.id);
        } else {
          if (this.selectedItems.indexOf(event.dataItem.id) !== -1) {
            let selectedData = this.selectedItems;
            for (const key in selectedData) {
              if (Object.hasOwnProperty.call(selectedData, key)) {
                if (selectedData[key] === event.dataItem.id) {
                  selectedData.splice(key, 1);
                }
              }
            }
            this.selectedItems = selectedData;
          }
        }
      }
      this.userSubscribedStatusHandler();
    },
    onSearch(e) {
      this.isSearching = true;
      this.$store.commit("SET_LOADER", true);
      this.searchWord = e.value;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.refetchGridData(true);
      }, 800);
    },
    dataStateChange: function (event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      const payload = this.payloadGenerator();
      payload.limit = event.data.take;
      payload.offset = event.data.skip;

      this.$store.dispatch("getAudience", payload);
    },
    createTag() {},
    handleListStatusValue(e) {
      this.isSearching = true;
      this.passListStatusValue = e.value;
      this.refetchGridData(true);
    },
    handleTagStatusValue(e) {
      this.isSearching = true;
      this.passTagStatusValue = e.value;
      this.refetchGridData(true);
    },
    handleSegmentStatusValue(e) {
      this.isSearching = true;
      this.passSegmentStatusValue = e.value;
      this.refetchGridData(true);
    },
    newSegment() {},
    getSegmentsLists() {
      let payload = {
        clubId: this.$route.params.id,
        searchQuery: "",
        entityType: this.$route.query.type || 1,
        // offset: 0,
        // limit: 100,
      };
      this.$store.dispatch("getsegmentsLists", payload);
    },
    btnAddAudience() {
      this.$store.commit("MODAL_VISIBLE", true);
      this.$store.commit("SET_LOADER", false);
    },
    openModal() {
      this.modalVisible = true;
    },
    openImportModal() {
      this.importModalVisible = true;
      this.closeMemberImport = true;
    },
    save(data) {
      let clubId = this.$route.params.id;
      let model = {
        documentId: data,
        clubId: clubId,
      };
      this.$store.dispatch("importMember", model);
    },
    onChangeDrodownImportExport(e) {
      if (e.item.id == 1) {
        this.exportList();
      }
      if (e.item.id == 0) {
        this.openImportModal();
      }
    },
    async exportList() {
      this.$store.commit("SET_LOADER_All_AUD", true);
      let payload = {
        entityType: +this.$route.query.type,
        clubId: this.$route.params.id,
        tagId:
          this.passTagStatusValue?.id == 0 ? null : this.passTagStatusValue?.id,
        listId:
          this.passListStatusValue?.id == 0
            ? null
            : this.passListStatusValue?.id,
        segmentId:
          this.passSegmentStatusValue?.id == 0
            ? null
            : this.passSegmentStatusValue?.id,
      };

      if (+this.$route.query.tagId) {
        payload = {
          entityType: +this.$route.query.type,
          clubId: this.$route.params.id,
          tagId: this.$route.query?.tagId,
          listId:
            this.passListStatusValue?.id == 0
              ? null
              : this.passListStatusValue?.id,
        };
      }
      let token = null;
      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Audience/export`,
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      })
        .then((response) => {
          this.$store.commit("SET_LOADER_All_AUD", false);
          if (response?.data?.type) {
            this.showNotification("Audience exported successfully.", "success");
            let fileURL = window.URL.createObjectURL(
              new Blob([response?.data])
            );
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let filename =
              +this.$route.query.type == 1
                ? "All audience of digital pass.xlsx"
                : +this.$route.query.type == 2
                ? "All Audience of email campaign.xlsx"
                : "All audience of voucher program.xlsx";
            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);
            fileLink.click();
          } else {
            const model = new SnackBarModel();
            model.type = "error";
            model.Message = "No audience to export.";
            this.$store.dispatch("showNotification", model);
          }
        })
        .catch((error) => {
          this.$store.commit("SET_LOADER_All_AUD", false);
          const model = new SnackBarModel();
          model.type = "error";
          model.Message = "No audience to export.";
          this.$store.dispatch("showNotification", model);
        });
    },
    modalHandler(data) {
      this.importModalVisible = data;
    },
    getopenMemberMapping(value) {
      this.closeMemberImport = false;
      this.openMembermapping = value.isModalOpen;
      if (this.openMembermapping) {
        this.$store.commit("IMPORT_MODAL_VISIBLE", value.isModalOpen);
      }
      this.importedDocId = value.doccumentId;
    },
    getCloseMemberImportModal(value) {
      this.closeMemberImport = false;
    },
    getopenMemberImport(value) {
      this.closeMemberImport = value;
      this.openMembermapping = false;
    },
    closeAllModals() {
      this.importModalVisible = false;
      this.closeMemberImport = false;
      this.openMembermapping = false;
    },
    getClub(e) {
      // this.$store.dispatch("getClub", e);
    },
    openConFigureFields() {
      // this.isOpenConfigureFields = true;
      if (this.$route.query.type == 3) {
        this.$router.push({
          name: "voucherConfigureFields",
          query: {
            type: +this.$route.query.type,
          },
        });
      } else if (this.$route.query.type == 2) {
        this.$router.push({
          name: "ECConfigureFields",
          query: {
            type: +this.$route.query.type,
          },
        });
      } else {
        this.$router.push({
          name: "DPConfigureFields",
          query: {
            type: +this.$route.query.type,
          },
        });
      }
    },
    cancel() {
      this.isOpenConfigureFields = false;
    },
    getAllFields() {
      const payload = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
      };
      this.$store.dispatch("getConfigureFields", payload);
    },
    deleteModal() {
      this.isSearching = false;
      this.$store.commit("MODAL_VISIBLE_COMMON", true);
    },
    selectedLength(e) {
      if (e == 0) this.selectedItems = [];
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>

<style></style>
